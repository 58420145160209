import * as React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { getImage } from "gatsby-plugin-image"
import moment from "moment"
import { reduceBy } from "../utils/utils"
import FootnoteProvider from "../providers/footnoteProvider"
import BlogImageProvider from "../providers/blogImageProvider"
import ImageWithAttribution from "../components/blog/imageWithAttribution"

const mcTranslate = `(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text'; 
$.extend($.validator.messages, {
    required: "Dieses Feld ist ein Pflichtfeld.",
    maxlength: $.validator.format("Geben Sie bitte maximal {0} Zeichen ein."),
    minlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
    rangelength: $.validator.format("Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein."),
    email: "Geben Sie bitte eine gültige E-Mail Adresse ein.",
    url: "Geben Sie bitte eine gültige URL ein.",
    date: "Bitte geben Sie ein gültiges Datum ein.",
    number: "Geben Sie bitte eine Nummer ein.",
    digits: "Geben Sie bitte nur Ziffern ein.",
    equalTo: "Bitte denselben Wert wiederholen.",
    range: $.validator.format("Geben Sie bitten einen Wert zwischen {0} und {1}."),
    max: $.validator.format("Geben Sie bitte einen Wert kleiner oder gleich {0} ein."),
    min: $.validator.format("Geben Sie bitte einen Wert größer oder gleich {0} ein."),
    creditcard: "Geben Sie bitte ein gültige Kreditkarten-Nummer ein."
});}(jQuery));var $mcj = jQuery.noConflict(true);`

const staticScripts = [
  {
    type: "text/javascript",
    src: "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js",
  },
  { type: "text/javascript", html: mcTranslate },
]

const BlogPage = ({
  data: {
    post,
    site: {
      siteMetadata: { siteUrl },
    },
  },
}) => {
  let images = reduceBy("name", post.images)
  for (const [name, image] of Object.entries(images)) {
    images[name].gImage = getImage(image)
  }
  const niceTime = moment(post.frontmatter.publishedAt)
  const formatted = niceTime.format("Do MMM YYYY, h:mm a")
  const keywords = post.frontmatter.keywords?.split(", ") ?? null

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        displayImage={`${siteUrl}${images.header.childImageSharp.gatsbyImageData.images.fallback.src}`}
        keywords={keywords}
        scripts={staticScripts}
      />
      <BlogImageProvider images={images}>
        <FootnoteProvider>
          <article>
            <div className="prose lg:prose-xl dark:prose-invert mx-auto max-w-5xl px-4 flex flex-col justify-center mb-8">
              <ImageWithAttribution imageName="header" />
              <div className="not-prose">
                <h1 className="font-sans font-bold leading-snug mt-8 -mb-5 text-5xl text-gray-900 dark:text-gray-100">
                  {post.frontmatter.title}
                </h1>
              </div>
              <p>
                Published by <Link to="/#about">Dennis Simon</Link> on{" "}
                <time dateTime={niceTime.toISOString()}>{formatted}</time>
              </p>
              <div>
                <MDXRenderer>{post.body}</MDXRenderer>
              </div>
            </div>
          </article>
        </FootnoteProvider>
      </BlogImageProvider>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PostQuery($id: String!) {
    post: mdx(id: { eq: $id }) {
      body
      frontmatter {
        publishedAt
        title
        description
        keywords
        slug
      }
      images {
        name
        childImageSharp {
          gatsbyImageData(width: 896, height: 500, placeholder: BLURRED)
        }
        imageMeta {
          attributionLink
          attributionName
          alt
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default BlogPage
